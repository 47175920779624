import {
  SwapSDK as ChainflipSDK,
  getChainflipId,
  type Chain as ChainflipChain,
  type Asset as ChainflipAsset,
  type DepositAddressRequest as ChainflipDepositAddressRequest,
  type QuoteRequest as ChainflipQuoteRequest,
  type ChainflipNetwork,
  type QuoteResponse,
  type ChainData,
  type SwapStatusResponseV2,
} from '@chainflip/sdk/swap';
import * as base58 from '@chainflip/utils/base58';
import {
  createAssociatedTokenAccountIdempotentInstruction,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
} from '@solana/spl-token';
import { type WalletContextState } from '@solana/wallet-adapter-react';
import { PublicKey, SystemProgram, Transaction, type Connection } from '@solana/web3.js';
import { isAxiosError } from 'axios';
import { ethers } from 'ethers';
import { erc20Abi } from 'viem';
import { chainById, type ChainId } from '@/shared/assets/chains';
import ChainflipMonochromaticLogo from '@/shared/assets/svg/monochromatic-logos/cf.svg';
import { FlipLogo } from '@/shared/assets/token-logos';
import { isChainflipTokenOrChain, type ChainflipToken } from '@/shared/assets/tokens';
import { NATIVE_TOKEN_ADDRESS } from '@/shared/assets/tokens/constants';
import { EGRESS_FEE, feeTypeMap, INGRESS_FEE } from '@/shared/constants';
import { type WalletClient, clientToSigner } from '@/shared/hooks/useEthersSigner';
import {
  TokenAmount,
  isTruthy,
  isHash,
  abbreviate,
  chainflipChainMap,
  chainflipAssetMap,
  getChainflipAsset,
  getChainflipNetwork,
  toUpperCase,
  entries,
} from '@/shared/utils';
import {
  assetConstants,
  buildChainflipExplorerLink,
  readAssetValue,
} from '@/shared/utils/chainflip';
import { convertFokMinPriceToReceivedAmount } from '@/shared/utils/convertFokMinPriceToReceivedAmount';
import { type BaseIntegration, getDeterministicRouteId } from './manager';
import {
  loadRouteFromLocalStorage,
  storeDepositChannelIdInLocalStorage,
  storeRouteInLocalStorage,
} from './storage';
import { getEgressAmount, getSwapDuration } from '../utils/sdk';
import {
  type ExecuteSwapResponse,
  type ChainflipRouteResponse,
  type ChainflipStatusResponse,
  type LegacyEventLog,
  type RouteRequest,
  type RouteResponse,
  type RouteResponseStep,
  type SwapStatus,
} from '.';

type ChainflipApproveVaultParams = Parameters<ChainflipSDK['approveVault']>[0];
type SwapFee = QuoteResponse['quote']['includedFees'][number];

const depositChannelIdRegex = /^(?<issuedBlock>\d+)-(?<srcChain>[a-z]+)-(?<channelId>\d+)$/i;
const transactionHashRegex = /^0x[a-f\d]+$/i;

const chainflipStatusMap: Record<SwapStatusResponseV2['state'], SwapStatus | undefined> = {
  WAITING: 'waiting_for_src_tx',
  RECEIVING: 'waiting_for_src_tx_confirmation',
  SWAPPING: 'waiting_for_dest_tx',
  SENDING: 'waiting_for_dest_tx',
  SENT: 'completed',
  COMPLETED: 'completed',
  FAILED: 'failed',
} as const;

export const mapChainIdToChainflip = (chainId: ChainId): ChainflipChain | undefined => {
  const chain = chainById(chainId);

  return isChainflipTokenOrChain(chain) ? chain.chainflipId : undefined;
};

const mapChainflipChain = (chainflipChain: ChainflipChain): ChainId => {
  if (chainflipChain in chainflipChainMap) {
    return chainflipChainMap[chainflipChain as keyof typeof chainflipChainMap].id;
  }

  throw new Error(`unexpected chain from chainflip sdk: ${chainflipChain}`);
};

const mapChainflipAsset = (
  chainflipChain: ChainflipChain,
  chainflipAsset: ChainflipAsset,
): ChainflipToken => {
  const chainflipId = getChainflipId({ asset: chainflipAsset, chain: chainflipChain });

  if (chainflipId in chainflipAssetMap) {
    return chainflipAssetMap[chainflipId as keyof typeof chainflipAssetMap];
  }

  throw new Error(`unexpected asset from chainflip sdk: ${chainflipId}`);
};

const mapChainflipStatus = (chainflipStatus: SwapStatusResponseV2['state']): SwapStatus =>
  chainflipStatusMap[chainflipStatus] ?? 'unknown';

export const cfIsBoostedChannel = (sdkStatus: SwapStatusResponseV2 | undefined) =>
  Boolean(sdkStatus && sdkStatus.boost?.maxBoostFeeBps && sdkStatus.boost.maxBoostFeeBps > 0);

export const cfIsBoostSkipped = (
  sdkStatus: SwapStatusResponseV2 | undefined,
): sdkStatus is SwapStatusResponseV2 & {
  boostSkippedAt: number;
  boostSkippedBlockIndex: string;
} => Boolean(sdkStatus && sdkStatus.boost?.skippedAt);

export const cfIsSwapBoosted = (
  sdkStatus: SwapStatusResponseV2 | undefined,
): sdkStatus is SwapStatusResponseV2 & {
  depositBoostedAt: number;
  depositBoostedBlockIndex: string;
} => Boolean(sdkStatus && sdkStatus.boost?.boostedAt);

type CompleteState = 'SENDING' | 'SENT' | 'COMPLETED' | 'FAILED';

type GetMatchingState<T, U> = T extends { state: U } ? T : never;

export const cfIsSwappingFinished = (
  sdkStatus: SwapStatusResponseV2 | undefined,
): sdkStatus is GetMatchingState<SwapStatusResponseV2, CompleteState> =>
  sdkStatus != null &&
  (sdkStatus.state === 'SENDING' ||
    sdkStatus.state === 'SENT' ||
    sdkStatus.state === 'COMPLETED' ||
    sdkStatus.state === 'FAILED');

const useBoostQuote = (sdkStatus: SwapStatusResponseV2 | undefined) =>
  cfIsBoostedChannel(sdkStatus) && !cfIsBoostSkipped(sdkStatus);

export const calculateMinPrice = (
  quote: ChainflipRouteResponse['integrationData'],
  slippageTolerancePercent: number,
) => {
  if (!quote?.quotedPrice) {
    throw new Error(`Missing quotedPrice when calculating minimum price`);
  }

  return String(quote!.quotedPrice! * (1 - slippageTolerancePercent / 100));
};

export const calculateMinReceived = (route: ChainflipRouteResponse, minPrice: string) =>
  convertFokMinPriceToReceivedAmount({
    srcAmount: route.srcAmount,
    ingressFee:
      route.platformFees.find((fee) => fee.name === INGRESS_FEE)?.amount ?? new TokenAmount(0),
    egressFee:
      route.platformFees.find((fee) => fee.name === EGRESS_FEE)?.amount ?? new TokenAmount(0),
    minPrice,
    destTokenDecimals: route.destToken.decimals,
  });

const Logo = () => ChainflipMonochromaticLogo({ style: { scale: '80%' } });

const buildLegacyEventLog = (
  sdkStatus: SwapStatusResponseV2,
  route: RouteResponse,
  depositChannelId: string | undefined,
  depositTransactionConfirmations: number | null | undefined,
): LegacyEventLog[] => {
  const pastEvents: LegacyEventLog[] = [];
  const srcAmountWithSymbol = `${route.srcAmount.toPreciseFixedDisplay()} ${route.srcToken.symbol}`;
  const destAmountWithSymbol = `${route.destAmount.toPreciseFixedDisplay()} ${
    route.destToken.symbol
  }`;
  const abbreviatedDestAddress = abbreviate(sdkStatus.destAddress);

  if (depositChannelId) {
    pastEvents.push({
      message: 'Deposit channel created',
      status: 'success',
      linkTitle: 'View on Explorer',
      logo: Logo,
      link: buildChainflipExplorerLink(depositChannelId, 'channels'),
    });
  }

  if (!('deposit' in sdkStatus && sdkStatus.deposit.witnessedAt) && !sdkStatus.boost?.boostedAt) {
    let message;
    if (!depositTransactionConfirmations) {
      message = 'Waiting to receive your funds';
    } else if (
      sdkStatus.srcChainRequiredBlockConfirmations &&
      depositTransactionConfirmations >= sdkStatus.srcChainRequiredBlockConfirmations
    ) {
      message = 'Waiting for witnessing to complete';
    } else {
      message = 'Waiting for block confirmations';
    }

    return [
      ...pastEvents,
      {
        message,
        status: 'processing',
        logo: Logo,
        link: undefined,
      },
    ];
  }

  if (cfIsBoostedChannel(sdkStatus)) {
    if (cfIsSwapBoosted(sdkStatus)) {
      pastEvents.push({
        message: `${srcAmountWithSymbol} deposit boosted after 1 block confirmation`,
        status: 'success',
        logo: Logo,
        link: buildChainflipExplorerLink(sdkStatus.boost?.boostedBlockIndex),
      });
      pastEvents.push({
        message: `Swap scheduled`,
        status: 'success',
        linkTitle: 'View on Explorer',
        logo: Logo,
        link: buildChainflipExplorerLink(sdkStatus.swapId, 'swaps'),
      });
    } else if (cfIsBoostSkipped(sdkStatus)) {
      pastEvents.push({
        message: 'Boost attempt failed. Not enough available liquidity',
        status: 'error',
        logo: Logo,
        link: undefined,
      });
    }
  }

  if ('deposit' in sdkStatus && sdkStatus.deposit.witnessedAt) {
    pastEvents.push({
      message: `${srcAmountWithSymbol} deposited`,
      status: 'success',
      logo: Logo,
      link: buildChainflipExplorerLink(sdkStatus.deposit.witnessedBlockIndex),
    });
    pastEvents.push({
      message: `Swap scheduled`,
      status: 'success',
      linkTitle: 'View on Explorer',
      logo: Logo,
      link: buildChainflipExplorerLink(sdkStatus.swapId, 'swaps'),
    });
  }

  if ('swapEgress' in sdkStatus && sdkStatus.swapEgress) {
    if (sdkStatus.swap?.regular) {
      pastEvents.push({
        message: `Swapped ${srcAmountWithSymbol} for ${destAmountWithSymbol} successfully`,
        status: 'success',
        logo: Logo,
        link: buildChainflipExplorerLink(sdkStatus.swap.regular.executedBlockIndex),
      });
    }

    if (sdkStatus.state === 'SENT' || sdkStatus.swapEgress.witnessedAt) {
      pastEvents.push({
        message: `${destAmountWithSymbol} sent to address ${abbreviatedDestAddress}`,
        status: 'success',
        logo: Logo,
        link: buildChainflipExplorerLink(sdkStatus.swapEgress.witnessedBlockIndex),
      });
    } else if (sdkStatus.swapEgress.scheduledAt) {
      pastEvents.push({
        message: `Sending ${destAmountWithSymbol} to the destination address ${abbreviatedDestAddress}`,
        status: 'processing',
        logo: Logo,
        link: undefined,
      });
    }
  } else if ('refundEgress' in sdkStatus && sdkStatus.refundEgress) {
    const abbreviatedRefundAddress = abbreviate(
      sdkStatus.depositChannel?.fillOrKillParams?.refundAddress,
    );
    const refundAmountWithSymbol = `${new TokenAmount(
      sdkStatus.refundEgress.amount,
      route.srcToken.decimals,
    ).toPreciseFixedDisplay()} ${route.srcToken.symbol}`;

    pastEvents.push({
      message: `Slippage tolerance exceeded`,
      status: 'error',
      logo: Logo,
      link: buildChainflipExplorerLink(sdkStatus.refundEgress.scheduledBlockIndex),
    });

    if (sdkStatus.state === 'SENT' || sdkStatus.refundEgress.witnessedAt) {
      pastEvents.push({
        message: `${refundAmountWithSymbol} sent to address ${abbreviatedRefundAddress}`,
        status: 'success',
        logo: Logo,
        link: buildChainflipExplorerLink(sdkStatus.refundEgress.witnessedBlockIndex),
      });
    } else if (sdkStatus.swapEgress) {
      pastEvents.push({
        message: `Sending ${refundAmountWithSymbol} to the address ${abbreviatedRefundAddress}`,
        status: 'processing',
        logo: Logo,
        link: undefined,
      });
    }
  } else {
    pastEvents.push({
      message: `Preparing to swap ${srcAmountWithSymbol} to ${route.destToken.symbol}`,
      status: 'processing',
      logo: Logo,
      link: undefined,
    });
  }

  if (sdkStatus.state === 'FAILED') {
    const index =
      sdkStatus.swapEgress?.failedBlockIndex ?? sdkStatus.refundEgress?.failedBlockIndex;
    pastEvents.push({
      message: 'Swap failed because of an unexpected reason',
      status: 'error',
      logo: Logo,
      link: buildChainflipExplorerLink(index),
    });
  }

  return pastEvents;
};

const buildRouteObject = (
  swapData: ChainflipQuoteRequest,
  quote: {
    intermediateAmount?: string;
    egressAmount: string;
    includedFees: SwapFee[];
    estimatedDurationSeconds: number;
    estimatedPrice?: string;
  },
  boostData: { isBoosted: boolean; defaultDurationSeconds: number },
  dcaParams?: {
    numberOfChunks: number;
    chunkIntervalBlocks: number;
  },
): ChainflipRouteResponse => {
  const srcToken = mapChainflipAsset(swapData.srcChain, swapData.srcAsset);
  const srcAmount = new TokenAmount(swapData.amount, srcToken.decimals);
  const destToken = mapChainflipAsset(swapData.destChain, swapData.destAsset);
  const destAmount = new TokenAmount(quote.egressAmount, destToken.decimals);

  const intermediateUsdcAmount = quote.intermediateAmount
    ? new TokenAmount(quote.intermediateAmount, chainflipAssetMap.Usdc.decimals)
    : undefined;

  const steps: RouteResponseStep<ChainflipToken>[] = intermediateUsdcAmount
    ? [
        {
          protocolName: 'Chainflip',
          protocolLink: undefined,
          srcToken,
          srcAmount,
          destAmount: intermediateUsdcAmount,
          destToken: chainflipAssetMap.Usdc,
        },
        {
          protocolName: 'Chainflip',
          protocolLink: undefined,
          srcToken: chainflipAssetMap.Usdc,
          srcAmount: intermediateUsdcAmount,
          destToken,
          destAmount,
        },
      ]
    : [
        {
          protocolName: 'Chainflip',
          protocolLink: undefined,
          srcToken,
          srcAmount,
          destToken,
          destAmount,
        },
      ];

  const platformFees = entries(feeTypeMap)
    .filter(([, name]) => name)
    .flatMap(([type, name]) =>
      quote.includedFees
        .filter((fee) => fee.type === type)
        .map((fee) => {
          const token = mapChainflipAsset(fee.chain, fee.asset);

          return {
            name: name as string,
            token,
            amount: new TokenAmount(fee.amount, token.decimals),
          };
        }),
    );

  const routeResponse = {
    integration: 'chainflip' as const,
    integrationData: {
      ...swapData,
      ...boostData,
      quotedPrice: quote.estimatedPrice ? Number(quote.estimatedPrice) : undefined,
      dcaParams,
    },
    srcToken,
    srcAmount,
    destToken,
    destAmount,
    steps,
    gasFees: [],
    platformFees,
    durationSeconds: quote.estimatedDurationSeconds,
  };

  return { ...routeResponse, id: getDeterministicRouteId(routeResponse) };
};

const transactionHashKey = (swapId: string) => `chainflip-deposit-transaction-${swapId}`;

const storeTransactionHashInLocalStorage = (swapId: string, txHash: string) => {
  localStorage.setItem(transactionHashKey(swapId), txHash);
};
const loadTransactionHashFromLocalStorage = (swapId: string): string | undefined =>
  localStorage.getItem(transactionHashKey(swapId)) ?? undefined;

const isBase58 = (str: string | undefined) => {
  if (!str) return false;

  try {
    base58.decode(str);
    return true;
  } catch {
    return false;
  }
};

const chainOrder: Record<ChainflipChain, number> = {
  Bitcoin: 0,
  Ethereum: 1,
  Solana: 2,
  Arbitrum: 3,
  Polkadot: Infinity,
};

const sortChains = (a: ChainData, b: ChainData) => chainOrder[a.chain] - chainOrder[b.chain];

export class ChainflipIntegration implements BaseIntegration {
  sdk = new ChainflipSDK({
    network: getChainflipNetwork() as ChainflipNetwork,
    backendUrl: process.env.NEXT_PUBLIC_CHAINFLIP_BACKEND_URL,
    rpcUrl: process.env.NEXT_PUBLIC_STATECHAIN_NODE_URI,
    enabledFeatures: { dca: true },
  });

  readonly name = 'Chainflip';

  readonly logo = FlipLogo;

  getChains = async () => {
    const sdkChains = await this.sdk.getChains();

    return sdkChains
      .sort(sortChains)
      .map((chain) => chainById(mapChainflipChain(chain.chain)))
      .filter(isTruthy);
  };

  getDestinationChains = async (srcChainId: ChainId) => {
    const chainflipChain = mapChainIdToChainflip(srcChainId);
    if (!chainflipChain) return [];

    const sdkChains = (await this.sdk.getChains(chainflipChain)) ?? [];

    return sdkChains
      .sort(sortChains)
      .map((chain) => chainById(mapChainflipChain(chain.chain)))
      .filter(isTruthy);
  };

  getRoutes = async (routeParams: RouteRequest) => {
    const srcChain = mapChainIdToChainflip(routeParams.srcChainId);
    const srcChainflipAsset = getChainflipAsset(
      routeParams.srcChainId,
      routeParams.srcTokenAddress,
    );
    const srcAsset = srcChainflipAsset ? assetConstants[srcChainflipAsset].rpcAsset : undefined;
    const destChain = mapChainIdToChainflip(routeParams.destChainId);
    const destChainflipAsset = getChainflipAsset(
      routeParams.destChainId,
      routeParams.destTokenAddress,
    );
    const destAsset = destChainflipAsset ? assetConstants[destChainflipAsset].rpcAsset : undefined;

    const { minimumAmount, maximumAmount } = await this.getSwapLimits(
      routeParams.srcChainId,
      routeParams.srcTokenAddress,
    );
    const { amount } = routeParams;

    if (
      !srcChain ||
      !srcAsset ||
      !destChain ||
      !destAsset ||
      amount < minimumAmount ||
      (maximumAmount && amount > maximumAmount)
    ) {
      return [];
    }

    const sdkQuote = await this.sdk.getQuoteV2({
      srcChain,
      destChain,
      srcAsset: toUpperCase(srcAsset),
      destAsset: toUpperCase(destAsset),
      amount: amount.toString(),
    });

    return sdkQuote.quotes
      .flatMap((quote) => [
        buildRouteObject(
          sdkQuote,
          quote,
          {
            isBoosted: false,
            defaultDurationSeconds: quote.estimatedDurationSeconds,
          },
          quote.dcaParams,
        ),
        quote.boostQuote &&
          buildRouteObject(
            sdkQuote,
            quote.boostQuote,
            {
              isBoosted: true,
              defaultDurationSeconds: quote.estimatedDurationSeconds,
            },
            quote.dcaParams,
          ),
      ])
      .filter(isTruthy);
  };

  getTokens = async (chainId: ChainId): Promise<ChainflipToken[]> => {
    const chainflipChain = mapChainIdToChainflip(chainId);
    if (!chainflipChain) return [];

    const sdkAssets = await this.sdk.getAssets(chainflipChain);

    return sdkAssets.map((asset) => mapChainflipAsset(asset.chain, asset.asset));
  };

  async getSwapLimits(
    chainId: ChainId,
    tokenAddress: string,
  ): Promise<{ maximumAmount: bigint | null; minimumAmount: bigint }> {
    const limits = await this.sdk.getSwapLimits();

    const chainflipAsset = getChainflipAsset(chainId, tokenAddress);
    if (!chainflipAsset) {
      return { minimumAmount: 0n, maximumAmount: null };
    }

    return {
      minimumAmount: readAssetValue(limits.minimumSwapAmounts, chainflipAsset),
      maximumAmount: readAssetValue(limits.maximumSwapAmounts, chainflipAsset),
    };
  }

  getStatus = async (swapId: string): Promise<ChainflipStatusResponse | undefined> => {
    const storedRoute = loadRouteFromLocalStorage('chainflip', swapId);
    const depositChannelId = depositChannelIdRegex.test(swapId)
      ? swapId
      : storedRoute?.depositChannelId;
    const localTransactionHash = transactionHashRegex.test(swapId)
      ? swapId
      : loadTransactionHashFromLocalStorage(swapId);

    const shareableId = depositChannelId ?? localTransactionHash;

    let sdkStatus: SwapStatusResponseV2 | undefined;

    if (shareableId) {
      try {
        sdkStatus = await this.sdk.getStatusV2({ id: shareableId });
      } catch (e) {
        // ignore 404: sdk will return status of transaction hash only after deposit is witnessed
        if (!(isAxiosError(e) && e.response?.status === 404)) throw e;
      }
    }

    let swapParams: ChainflipDepositAddressRequest & { useBoostQuote: boolean };

    if (sdkStatus && sdkStatus.state !== 'FAILED') {
      const srcAmount =
        'deposit' in sdkStatus
          ? sdkStatus.deposit.amount
          : sdkStatus.depositChannel.expectedDepositAmount ?? '0';

      swapParams = {
        ...sdkStatus,
        amount: srcAmount,
        useBoostQuote: useBoostQuote(sdkStatus),
      };
    } else if (sdkStatus?.state === 'FAILED') {
      // for smart contract failures, we don't know the destination
      if (!sdkStatus.destChain || !sdkStatus.destAsset) return undefined;

      swapParams = {
        ...sdkStatus,
        amount: sdkStatus.deposit.amount ?? '0',
        useBoostQuote: useBoostQuote(sdkStatus),
      };
    } else if (storedRoute && storedRoute.integration === 'chainflip') {
      swapParams = {
        ...storedRoute.integrationData,
        destAddress: storedRoute.destAddress,
        useBoostQuote: storedRoute.integrationData.isBoosted,
        amount: storedRoute.srcAmount.toString(),
      };
    } else {
      return undefined;
    }

    const fees =
      sdkStatus && 'fees' in sdkStatus && sdkStatus.fees
        ? sdkStatus.fees.filter((fee) => fee.type !== 'LIQUIDITY')
        : undefined;

    // TODO(dca): ?????????????????????
    // get quote data from status if the amounts are locked in, otherwise fetch fresh quote
    let quoteData: Omit<
      QuoteResponse['quote'],
      'type' | 'poolInfo' | 'lowLiquidityWarning' | 'estimatedPrice'
    >;
    let defaultDurationSeconds = 0;
    if (sdkStatus && cfIsSwappingFinished(sdkStatus)) {
      quoteData = {
        egressAmount: getEgressAmount(sdkStatus) ?? '0',
        intermediateAmount: sdkStatus.swap.swappedIntermediateAmount,
        includedFees: fees ?? [],
        estimatedDurationSeconds: 0,
        dcaParams: sdkStatus.depositChannel?.dcaParams,
      };
    } else {
      const sdkQuotes = await this.sdk.getQuoteV2(swapParams);
      const dcaParams =
        storedRoute?.integration === 'chainflip'
          ? storedRoute.integrationData.dcaParams
          : sdkStatus?.depositChannel?.dcaParams;
      const quote =
        sdkQuotes.quotes.find(
          (q) =>
            q.dcaParams?.chunkIntervalBlocks === dcaParams?.chunkIntervalBlocks &&
            q.dcaParams?.numberOfChunks === dcaParams?.numberOfChunks,
        ) ?? sdkQuotes.quotes[0];

      swapParams.useBoostQuote = swapParams.useBoostQuote && Boolean(quote.boostQuote); // set to false if no boost quote is available
      quoteData = swapParams.useBoostQuote
        ? { ...quote.boostQuote!, dcaParams: quote.dcaParams }
        : quote;
      defaultDurationSeconds = quote.estimatedDurationSeconds;
    }

    const route = buildRouteObject(
      swapParams,
      quoteData,
      {
        isBoosted: swapParams.useBoostQuote,
        defaultDurationSeconds,
      },
      quoteData.dcaParams,
    );

    const duration = getSwapDuration(sdkStatus);

    const deposit = sdkStatus && 'deposit' in sdkStatus ? sdkStatus.deposit : undefined;

    let srcTransactionHash;
    if (deposit?.txRef) {
      srcTransactionHash = deposit.txRef;
    } else if (
      ['Ethereum', 'Arbitrum'].includes(swapParams.srcChain) &&
      isHash(localTransactionHash)
    ) {
      srcTransactionHash = localTransactionHash;
    } else if (swapParams.srcChain === 'Solana' && isBase58(localTransactionHash)) {
      srcTransactionHash = localTransactionHash;
    }

    const srcConfirmationCount = deposit?.txConfirmations;

    let status = sdkStatus ? mapChainflipStatus(sdkStatus.state) : 'waiting_for_src_tx';
    if (
      status === 'waiting_for_src_tx' &&
      (srcTransactionHash || srcConfirmationCount !== undefined)
    ) {
      status = 'waiting_for_src_tx_confirmation'; // show receiving after tx was submitted via connected wallet
    }

    return {
      id: swapId,
      shareableId,
      integration: 'chainflip' as const,
      integrationData: sdkStatus,
      status,
      route,
      swapExplorerUrl: undefined,
      depositAddress: sdkStatus?.depositChannel?.depositAddress,
      srcTransactionHash,
      srcConfirmationCount,
      destAddress: swapParams.destAddress,
      destTransactionHash: undefined,
      duration,
      legacyEventLogs: sdkStatus
        ? buildLegacyEventLog(sdkStatus, route, depositChannelId, srcConfirmationCount).reverse()
        : [],
    };
  };

  createDepositChannel = async (
    swapId: string,
    options: {
      srcAddress?: string;
      maxBoostFeeBps?: number;
      slippageParams?: { slippageTolerancePercent: number; swapDeadlineMinutes: number };
    },
  ) => {
    const preparedRoute = loadRouteFromLocalStorage('chainflip', swapId);
    if (!preparedRoute || preparedRoute.integration !== 'chainflip') {
      throw new Error(`Invalid route when opening deposit channel`);
    }

    const { slippageParams, ...remainingOptions } = options;

    let fillOrKillParams;
    if (slippageParams) {
      if (!preparedRoute.refundAddress) {
        throw new Error(`Missing refund address when opening deposit channel`);
      }

      if (!preparedRoute.integrationData?.quotedPrice) {
        throw new Error(`Missing quote price when opening deposit channel`);
      }

      fillOrKillParams = {
        retryDurationBlocks: slippageParams.swapDeadlineMinutes * 10,
        refundAddress: preparedRoute.refundAddress,
        minPrice: calculateMinPrice(
          preparedRoute.integrationData,
          slippageParams.slippageTolerancePercent,
        ),
      };
    }

    const response = await this.sdk.requestDepositAddress({
      destAddress: preparedRoute.destAddress,
      fillOrKillParams,
      ...preparedRoute.integrationData,
      ...remainingOptions,
    });
    storeDepositChannelIdInLocalStorage('chainflip', swapId, response.depositChannelId);

    return response;
  };

  private executeSwap = async (
    swapId: string,
    cb: (status: ChainflipStatusResponse, destAddress: string) => Promise<string>,
  ) => {
    const status = await this.getStatus(swapId);
    if (!status) {
      throw new Error(`Status of swap "${swapId}" not found`);
    }
    if (status.route.integration !== 'chainflip') {
      throw new Error(`Unexpected route when executing swap "${swapId}"`);
    }
    if (!status.destAddress) {
      throw new Error(`Missing dest address when executing swap "${swapId}"`);
    }

    const transactionHash = await cb(status, status.destAddress);

    storeTransactionHashInLocalStorage(status.shareableId ?? status.id, transactionHash);
    // store route data as sdk will return status for transaction hash only after deposit was witnessed
    storeRouteInLocalStorage('chainflip', transactionHash, {
      ...status.route,
      destAddress: status.destAddress,
    });

    return {
      integration: 'chainflip' as const,
      integrationData: transactionHash,
      error: undefined,
    };
  };

  executeEvmSwap = async (swapId: string, walletClient: WalletClient) =>
    this.executeSwap(swapId, async (status, destAddress) => {
      const signer = clientToSigner(walletClient);

      if (status.depositAddress) {
        const submittedTransaction = await (status.route.srcToken.address === NATIVE_TOKEN_ADDRESS
          ? signer.sendTransaction({
              value: status.route.srcAmount.toString(),
              to: status.depositAddress,
            })
          : new ethers.Contract(status.route.srcToken.address, erc20Abi, signer).transfer(
              status.depositAddress,
              status.route.srcAmount.toString(),
            ));

        return submittedTransaction.hash;
      }

      const sdkWithSigner = new ChainflipSDK({
        signer,
        backendUrl: process.env.NEXT_PUBLIC_CHAINFLIP_BACKEND_URL,
        network: getChainflipNetwork() as ChainflipNetwork,
        rpcUrl: process.env.NEXT_PUBLIC_STATECHAIN_NODE_URI,
        enabledFeatures: { dca: true },
      });
      if (status.route.srcToken.address !== NATIVE_TOKEN_ADDRESS) {
        await sdkWithSigner.approveVault(
          {
            ...status.route.integrationData,
            amount: status.route.integrationData.amount,
          } as ChainflipApproveVaultParams,
          { wait: 1 },
        );
      }

      return sdkWithSigner.executeSwap({ ...status.route.integrationData, destAddress });
    });

  async executeSolanaSwap(
    swapId: string,
    wallet: WalletContextState,
    connection: Connection,
  ): Promise<ExecuteSwapResponse> {
    return this.executeSwap(swapId, async (status) => {
      if (!wallet.publicKey) {
        throw new Error(`Missing public key when executing swap "${swapId}"`);
      }
      if (!status.depositAddress) {
        throw new Error(`Missing deposit address when executing swap "${swapId}"`);
      }

      const depositAddress = new PublicKey(status.depositAddress);

      let instructions;

      if (status.route.srcToken.chainflipId === 'Sol') {
        instructions = [
          SystemProgram.transfer({
            fromPubkey: wallet.publicKey,
            toPubkey: depositAddress,
            lamports: status.route.srcAmount.toBigInt(),
          }),
        ];
      } else {
        const mint = new PublicKey(status.route.srcToken.address);
        const associatedTokenFrom = await getAssociatedTokenAddress(mint, wallet.publicKey);
        const fromAccount = await getAccount(connection, associatedTokenFrom);
        const associatedTokenTo = await getAssociatedTokenAddress(mint, depositAddress, true);

        instructions = [
          createAssociatedTokenAccountIdempotentInstruction(
            wallet.publicKey,
            associatedTokenTo,
            depositAddress,
            mint,
          ),
          createTransferInstruction(
            fromAccount.address,
            associatedTokenTo,
            wallet.publicKey,
            status.route.srcAmount.toBigInt(),
          ),
        ];
      }

      const tx = new Transaction().add(...instructions);

      return wallet.sendTransaction(tx, connection);
    });
  }
}
