import {
  ArbitrumLogo,
  BitcoinLogo,
  EthereumLogo,
  PolkadotLogo,
  SolanaLogo,
} from '@/shared/assets/chain-logos';
import {
  ArbitrumTransparentLogo,
  BitcoinTransparentLogo,
  EthereumTransparentLogo,
  PolkadotTransparentLogo,
  SolanaTransparentLogo,
} from '@/shared/assets/chain-transparent-logos';
import {
  BtcLogo,
  DotLogo,
  EthLogo,
  FlipLogo,
  SolLogo,
  UsdcLogo,
  UsdtLogo,
} from '@/shared/assets/token-logos';
import { isTestnet } from '@/shared/featureFlags';
import { type ChainflipNetwork, getChainflipNetwork } from './env';
import { unreachable } from './guards';
import { arbiscanUrl, etherscanUrl, solscanUrl } from './helpers';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getChainflipAssets = (network: ChainflipNetwork) =>
  ['Eth', 'Flip', 'Usdc', 'Usdt', 'Btc', 'Dot', 'ArbUsdc', 'ArbEth', 'Sol', 'SolUsdc'] as const;

export const chainflipAssets = getChainflipAssets(getChainflipNetwork());
export type ChainflipAsset = (typeof chainflipAssets)[number];

export type BaseChainflipAsset = Exclude<ChainflipAsset, 'Usdc'>;
export const baseChainflipAssets = chainflipAssets.filter(
  (asset) => asset !== 'Usdc',
) as BaseChainflipAsset[];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getChainflipChains = (network: ChainflipNetwork) =>
  ['Bitcoin', 'Ethereum', 'Solana', 'Arbitrum', 'Polkadot'] as const;

export const chainflipChains = getChainflipChains(getChainflipNetwork());
export type ChainflipChain = (typeof chainflipChains)[number];

export const assetConstants = {
  Eth: {
    chain: 'Ethereum',
    rpcAsset: 'ETH',
    decimals: 18,
    color: '#268AFF',
    logo: EthLogo,
  },
  Flip: {
    chain: 'Ethereum',
    rpcAsset: 'FLIP',
    decimals: 18,
    color: '#FFFFFF',
    logo: FlipLogo,
  },
  Usdc: {
    chain: 'Ethereum',
    rpcAsset: 'USDC',
    decimals: 6,
    color: '#FFFFFF',
    logo: UsdcLogo,
  },
  Usdt: {
    chain: 'Ethereum',
    rpcAsset: 'USDT',
    decimals: 6,
    color: '#4C9D86',
    logo: UsdtLogo,
  },
  Dot: {
    chain: 'Polkadot',
    rpcAsset: 'DOT',
    decimals: 10,
    color: '#FF33AF',
    logo: DotLogo,
  },
  Btc: {
    chain: 'Bitcoin',
    rpcAsset: 'BTC',
    decimals: 8,
    color: '#FFD2A8',
    logo: BtcLogo,
  },
  ArbUsdc: {
    chain: 'Arbitrum',
    rpcAsset: 'USDC',
    decimals: 6,
    color: '#9DCCED',
    logo: UsdcLogo,
  },
  ArbEth: {
    chain: 'Arbitrum',
    rpcAsset: 'ETH',
    decimals: 18,
    color: '#455E7D',
    logo: EthLogo,
  },
  Sol: {
    chain: 'Solana',
    rpcAsset: 'SOL',
    decimals: 9,
    color: '#C9A8FF',
    logo: SolLogo,
  },
  SolUsdc: {
    chain: 'Solana',
    rpcAsset: 'USDC',
    decimals: 6,
    color: '#9DCCED',
    logo: UsdcLogo,
  },
} as const satisfies Record<
  ChainflipAsset,
  {
    chain: ChainflipChain;
    rpcAsset: string;
    decimals: number;
    color: string;
    logo: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
  }
>;

export const addressTypes = ['Eth', 'Btc', 'Dot', 'Arb', 'Sol'] as const;
export type AddressType = (typeof addressTypes)[number];

export const boostFeeTiers = [5, 10, 30];
export type BoostFeeTier = (typeof boostFeeTiers)[number];

export const chainConstants = {
  Bitcoin: {
    assets: ['Btc'],
    gasAsset: 'Btc',
    logo: BitcoinLogo,
    transparentLogo: BitcoinTransparentLogo,
    addressType: 'Btc',
    blockTimeSeconds: 10 * 60,
  },
  Ethereum: {
    assets: ['Eth', 'Flip', 'Usdc', 'Usdt'],
    gasAsset: 'Eth',
    logo: EthereumLogo,
    transparentLogo: EthereumTransparentLogo,
    addressType: 'Eth',
    blockTimeSeconds: 12,
  },
  Solana: {
    assets: ['Sol', 'SolUsdc'],
    gasAsset: 'Sol',
    logo: SolanaLogo,
    transparentLogo: SolanaTransparentLogo,
    addressType: 'Sol',
    blockTimeSeconds: 0.8,
  },
  Arbitrum: {
    assets: ['ArbUsdc', 'ArbEth'],
    gasAsset: 'ArbEth',
    logo: ArbitrumLogo,
    transparentLogo: ArbitrumTransparentLogo,
    addressType: 'Arb',
    blockTimeSeconds: 0.26,
  },
  Polkadot: {
    assets: ['Dot'],
    gasAsset: 'Dot',
    logo: PolkadotLogo,
    transparentLogo: PolkadotTransparentLogo,
    addressType: 'Dot',
    blockTimeSeconds: 6,
  },
} as const satisfies Record<
  ChainflipChain,
  {
    assets: readonly ChainflipAsset[];
    gasAsset: ChainflipAsset;
    logo: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
    transparentLogo: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
    addressType: AddressType;
    blockTimeSeconds: number;
  }
>;

export const buildChainflipExplorerLink = (
  blockIndex: string | undefined,
  path: 'events' | 'swaps' | 'channels' = 'events',
) => {
  if (!blockIndex) return undefined;

  return new URL(`/${path}/${blockIndex}`, process.env.NEXT_PUBLIC_EXPLORER_URL).toString();
};

export const buildExplorerLink = (
  chain: ChainflipChain,
  type: 'address' | 'block' | 'tx',
  value: string | number,
): string => {
  const blockstreamUrl = `https://blockstream.info${isTestnet ? '/testnet' : ''}`;

  switch (chain) {
    case 'Bitcoin':
      if (type === 'block') {
        return `${blockstreamUrl}/block-height/${value}?q=${value}`;
      }
      if (type === 'address') {
        return `${blockstreamUrl}/address/${value}`;
      }
      if (type === 'tx') {
        return `${blockstreamUrl}/tx/${value}`;
      }
      return unreachable(type, 'unhandled link type');
    case 'Polkadot':
      if (type === 'block') return `https://polkadot.subscan.io/block/${value}`;
      if (type === 'address') {
        return `https://polkadot.subscan.io/account/${value}`;
      }
      if (type === 'tx') {
        return `https://polkadot.subscan.io/extrinsic/${value}`;
      }
      return unreachable(type, 'unhandled link type');
    case 'Ethereum':
      if (type === 'block') return `${etherscanUrl()}/block/${value}`;
      if (type === 'address') return `${etherscanUrl()}/address/${value}`;
      if (type === 'tx') return `${etherscanUrl()}/tx/${value}`;
      return unreachable(type, 'unhandled link type');
    case 'Arbitrum':
      if (type === 'block') return `${arbiscanUrl()}/block/${value}`;
      if (type === 'address') return `${arbiscanUrl()}/address/${value}`;
      if (type === 'tx') return `${arbiscanUrl()}/tx/${value}`;
      return unreachable(type, 'unhandled link type');
    case 'Solana':
      if (type === 'block') return `${solscanUrl(`/block/${value}`)}`;
      if (type === 'address') return `${solscanUrl(`/account/${value}`)}`;
      if (type === 'tx') return `${solscanUrl(`/tx/${value}`)}`;
      return unreachable(type, 'unhandled link type');
    default:
      return unreachable(chain, 'unhandled chain');
  }
};

export type AssetOfChain<C extends ChainflipChain> = (typeof chainConstants)[C]['assets'][number];

export type ChainAssetMap<T> = {
  [C in ChainflipChain]: {
    [A in (typeof assetConstants)[AssetOfChain<C>]['rpcAsset']]: T;
  };
};

export type BaseChainAssetMap<T> = {
  [C in ChainflipChain]: {
    [A in (typeof assetConstants)[Exclude<AssetOfChain<C>, 'Usdc'>]['rpcAsset']]: T;
  };
};

type AssetAndChain = {
  [C in ChainflipChain]: { chain: C; asset: keyof ChainAssetMap<unknown>[C] };
}[ChainflipChain];

// a ChainAssetMap can be safely used with quote or base assets
export function readAssetValue<T>(
  map: ChainAssetMap<T>,
  asset: ChainflipAsset | BaseChainflipAsset,
): T;
// a BaseChainAssetMap can only be safely used with base assets
export function readAssetValue<T>(map: BaseChainAssetMap<T>, asset: BaseChainflipAsset): T;
// a BaseChainAssetMap can only be safely used with base assets
export function readAssetValue<T>(
  map: ChainAssetMap<T> | BaseChainAssetMap<T>,
  asset: BaseChainflipAsset,
): T;
export function readAssetValue<T>(
  map: ChainAssetMap<T> | BaseChainAssetMap<T>,
  asset: ChainflipAsset | BaseChainflipAsset,
): T {
  const chainValues = map[assetConstants[asset].chain];
  return chainValues[assetConstants[asset].rpcAsset as keyof typeof chainValues];
}

export const internalAssetToRpcAsset: Record<ChainflipAsset, AssetAndChain> = {
  Eth: { chain: 'Ethereum', asset: 'ETH' },
  Flip: { chain: 'Ethereum', asset: 'FLIP' },
  Usdc: { chain: 'Ethereum', asset: 'USDC' },
  Usdt: { chain: 'Ethereum', asset: 'USDT' },
  Dot: { chain: 'Polkadot', asset: 'DOT' },
  Btc: { chain: 'Bitcoin', asset: 'BTC' },
  ArbUsdc: { chain: 'Arbitrum', asset: 'USDC' },
  ArbEth: { chain: 'Arbitrum', asset: 'ETH' },
  Sol: { chain: 'Solana', asset: 'SOL' },
  SolUsdc: { chain: 'Solana', asset: 'USDC' },
};
